<template>
  <ul
    class="flex flex-wrap text-sm md:text-base"
    v-if="breadcrumbList.length"
  >
    <li>
      <router-link
        class="breadcrumb-link"
        :to="{ name: 'home' }"
      >
        <i class="fas fa-home"></i>
      </router-link>
    </li>
    <li
      class="whitespace-nowrap"
      v-for="(breadcrumb, index) in breadcrumbList"
      :key="index"
    >
      <router-link
        class="breadcrumb-link"
        v-if="!!breadcrumb.link"
        :to="{ name: breadcrumb.link }"
      >
        <i v-if="!!breadcrumb.icon" class="mr-1" :class="breadcrumb.icon"></i>
        {{ $t(breadcrumb.name) }}
      </router-link>
      <div v-else>
        <i v-if="!!breadcrumb.icon" class="mr-1" :class="breadcrumb.icon"></i>
        {{ $t(breadcrumb.name) }}
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

interface IBreadcrumb {
  name: string;
  link?: string;
}

export default defineComponent({
  setup () {
    const router = useRouter()
    const breadcrumbList = ref<IBreadcrumb[]|null>(null)
    
    watchEffect(() => {
      const routerMeta = router.currentRoute.value.meta
      if ('breadcrumb' in routerMeta) {
        const breadcrumb = routerMeta.breadcrumb as IBreadcrumb[]
        breadcrumbList.value = breadcrumb
      }
    })

    return {
      breadcrumbList
    }
  }
})
</script>

<style scoped>
ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  list-style-type: none;
  font-weight: 600;
}
ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
ul > li > div, ul > li > div i {
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
}
ul > li:not(:last-child)::after {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: auto;
  --tw-text-opacity: 1;
  color: rgba(113, 113, 122, var(--tw-text-opacity));
  content: '/';
}
.breadcrumb-link, .breadcrumb-link i {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
  cursor: pointer;
}
.breadcrumb-link:hover, .breadcrumb-link i:hover {
  background-color: transparent;
  text-decoration: underline;
}
</style>
